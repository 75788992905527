import './core';
import '../css/main.css';

import '../images/th_project_ml_2_large.jpg';
import '../images/th_project_jb_1_large.jpg';
import '../images/th_project_am_3_large.jpg';

import '../images/th_project_jb_1_small.jpg';
import '../images/th_project_jb_3_small.jpg';
import '../images/th_project_jb_4_small.jpg';

import '../images/th_project_kh_3_small.jpg';
import '../images/th_project_kh_2_small.jpg';
import '../images/th_project_kh_7_small.jpg';

import '../images/th_project_ml_5_small.jpg';
import '../images/th_project_ml_2_small.jpg';
import '../images/th_project_ml_15_small.jpg';

// Projects
const projectJB = document.getElementById('project-jb');
if (projectJB) {
  projectJB.addEventListener('click', () => {
    window.location.href = '/realisaties/project-jb';
  });
}

const projectML = document.getElementById('project-ml');
if (projectML) {
  projectML.addEventListener('click', () => {
    window.location.href = '/realisaties/project-ml';
  });
}

const projectKH = document.getElementById('project-kh');
if (projectKH) {
  projectKH.addEventListener('click', () => {
    window.location.href = '/realisaties/project-kh';
  });
}

// Slideshow

$(function () {
  new AutoSlider('.jumbo-container');
});

class AutoSlider {
  constructor(element) {
    this.el = document.querySelector(element);
    this.init();
  }

  init() {
    this.slides = this.el.querySelectorAll('.jumbo-slide');
    this.index = 0;
    this.timer = null;
    this.delay = 7000;
    this.action();
  }

  _slideTo(slide) {
    const currentSlide = this.slides[slide];
    currentSlide.style.opacity = 1;

    for (let i = 0; i < this.slides.length; i++) {
      let slide = this.slides[i];
      if (slide !== currentSlide) {
        slide.style.opacity = 0;
      }
    }
  }

  action() {
    this.timer = setInterval(
      function () {
        this.index++;
        if (this.index == this.slides.length) {
          this.index = 0;
        }
        this._slideTo(this.index);
      }.bind(this),
      this.delay
    );
  }
}

import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

const r = document.getElementsByClassName('realisaties');

const anim = gsap.fromTo(r, { opacity: 0 }, { duration: 1, opacity: 1 });

ScrollTrigger.create({
  trigger: r,
  animation: anim,
  start: 'top 95%',
  end: 'center 75%',
});

gsap.utils.toArray('.project').forEach((project) => {
  const anim = gsap.fromTo(project, { opacity: 0 }, { opacity: 1 });

  ScrollTrigger.create({
    trigger: project,
    animation: anim,
    start: 'top 90%',
    end: 'center 70%',
  });
});
