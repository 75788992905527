import '../css/main.css';

import '../images/favicon-16x16.png';
import '../images/favicon-32x32.png';
import '../images/favicon.ico';
import '../images/instagram.svg';
import '../images/th-logo.png';

// Header
const circle = document.getElementById('circle');

if (circle) {
  circle.addEventListener('click', () => {
    window.location.href = '/contact';
  });
}

$(function () {
  window.addEventListener('scroll', onScroll);
});

// Scroll
const navbar = document.getElementById('header');
const title = document.getElementById('title');
const scrollTitle = document.getElementById('scroll-title');

function onScroll() {
  if (window.scrollY && window.pageYOffset > 80) {
    scrollTitle.style.display = 'block';
    scrollTitle.classList.add('title-scroll');
    scrollTitle.style.transition = '.5s';
    title.style.display = 'none';
    navbar.classList.add('nav-scroll');
  } else {
    scrollTitle.style.display = 'none';
    scrollTitle.classList.remove('title-scroll');
    title.style.display = 'block';
    navbar.classList.remove('nav-scroll');
  }
}

// Mobile menu
const btn = document.querySelector('button.mobile-menu-button');
const menu = document.querySelector('.mobile-menu');
const menuOpen = document.querySelector('.menu-open');
const menuClosed = document.querySelector('.menu-closed');

function onMobileButtonClick() {
  menu.classList.toggle('hidden');
  menuOpen.classList.toggle('hidden');
  menuClosed.classList.toggle('hidden');
}

if (btn) {
  btn.addEventListener('click', onMobileButtonClick);
}
